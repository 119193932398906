body {
  margin: 0;
  font-family: Quicksand!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Quicksand;
  src: url('./font/Quicksand-Medium.ttf');
}

.site-layout .site-layout-background {
  background: #fff;
}

html > canvas {
  display: none !important;
}

html > body > iframe {
  display: none !important;
}

