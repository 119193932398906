.pending-badge{
    background-color: red;
    color: #FFFF;
    /* width: 25px;
    height: 25px; */
    text-align: center;
    border-radius: 3px;
  }
  
  .success-badge{
    background-color: lightgreen;
    color: #FFFF;
    /* width: 25px;
    height: 25px; */
    text-align: center;
    border-radius: 3px;
  }
  
  .paid-badge{
    color:lightgreen!important;
    font-size: 27px;
  }

  .table-custom-list tbody >tr > td{
    font-size: 16px!important;
    padding: 5px 8px ;
  }

  .table-custom-list thead >tr > th{
    padding: 10px 10px ;
  }

.reset-btn{
  background-color: dimgray!important;
  border-color: dimgray!important;
  color: #FFFF!important;
}

.reset-btn:hover{
  background-color: gray!important;
  border-color: lightblue!important;
  color: lightblue!important;
}

.custom-add-btn{
  color: green!important;
  border-color: green!important;
  width: 32px!important;
  height: 32px!important;
  padding: 0!important;
  border-radius: 100px!important;
  transition: all .3s ease;
  font-weight: bold!important;
}

.custom-add-btn:hover{
  transform: scale(1.3) rotate(360deg);
  background-color: green!important;
  color: #FFFF!important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px!important;
}

/* .custom-add-btn:active{
  transform: scale(0.8);
} */

.trigger{
  font-size: 20px;
}

/* invoice */
.table-bill-to{
  width: 100%;
}

.table-description{
  width: 100%;
}

.table-bill-to tr td,.table-bill-to tr th{
  /* border: 1px solid #CCC; */
  font-size: 12px!important;
  border-collapse: collapse!important;
}

.table-bill-to tr td{
  padding:1px 0px;
}

.table-description tr td{
  padding:2px 15px;
}

.table-description tr td,.table-description tr th{
  border: 1px solid #29aae1;
  font-size: 12px!important;
  border-collapse: collapse!important;
}

.table-description tr th{
  background-color: #29aae1;
  color: #fff;
}

.table-bill-to tr th{
  background-color: #29aae1;
  color: #fff;
}

.footer-container{
  position: absolute;
  bottom: -20px;
  left: 60px;
}

.footer-container-unfixed{
  position: relative;
  margin-top: 10px;
}

.col-foot{
  /* position:absolute; */
  bottom:10px;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  text-align: center;
  font-size: 12px;
}

.table-print{
  border:1px solid #29aae1;
}

.table-print tbody >tr > td{
  font-size: 12px!important;
  padding: 2px 8px ;
}

.table-print thead >tr > th{
  font-size: 12px!important;
  padding: 2px 10px ;
}

.void-row td, .void-row td:hover{
  background-color: #eeee!important;
}

.void-row{
  cursor:not-allowed;
}

.paid-row td, .paid-row td:hover{
  background-color: #eeee!important;
}

.paid-row{
  cursor:not-allowed;
}

.salary-text{
  position: absolute;
  bottom: 0px;
  left: 25px;
}

.table-print-summary{
  /* border:1px solid #29aae1; */
  border-collapse: collapse!important;
}

.table-print-summary tbody >tr > td{
  font-size: 13px!important;
  padding: 4px 8px ;
}

.table-print-summary thead >tr > th{
  font-size: 13px!important;
  padding: 4px 10px ;
  background-color: #29aae1;
  border-radius: 0px!important;
  font-weight: bold;
  color: #ffff;
}

.table-print-header thead >tr > th{
  font-size: 13px!important;
  padding: 4px 10px ;
  background-color: #29aae1;
  border-radius: 0px!important;
  font-weight: bold;
  color: #ffff;
  border: none!important;
  text-align: center!important;
}

.table-print-header .ant-table-tbody{
  display: none!important;
}

.table-print-header tbody >tr > td{
  font-size: 13px!important;
  padding: 4px 8px ;
}

.dot-menu{
  transform: rotate(90deg) scale(1.3);
}

.is-staff-row td, .is-staff-row td:hover{
  background-color: #e6f7ff!important;
}

.deleted-row td {
  background-color:#ffecf8 !important ;
}
/* .is-staff-row{
  cursor:not-allowed;
} */